<template>
    <modalog :has-header="false">
        <template #content>
            <h2 class="dialog__title dialog__title--no-header">Delete Item</h2>
            <p>Are you sure you want to delete <strong v-html="props.item.name"></strong> from your wish list?</p>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--warning" @click="deleteItem" :disabled="modalogStore.isThrobbing">Delete</button>
        </template>
    </modalog>
</template>
<script setup>
import { router } from "@inertiajs/vue3";
import { useForm } from "@inertiajs/vue3";
import { useModalogStore } from "@/stores/modalog";

const modalogStore = useModalogStore();

const props = defineProps({
    item: {
        type: Object,
        default: {}
    },
    redirectUrl: {
        type: String,
        default: null
    }
});

const form = useForm({
    item_uuid: props.item.uuid,
});

const deleteItem = function() {
    modalogStore.isThrobbing = true;

    axios.delete(route('item.delete', { item: props.item.uuid }))
        .then(() => {
            if  (props.redirectUrl) {
                window.location = props.redirectUrl;
            }

            router.reload({ only: ['items']});
        })
        .catch((error) => console.error(error))
        .finally(() => {
            modalogStore.closeModalog();
            modalogStore.isThrobbing = false;
        });
}
</script>
