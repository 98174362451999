<template>
    <modalog :has-header="true" size="medium">
        <template #title>{{ titleText }}</template>
        <template #content>
            <div class="hint-panel is-error arrow-bottom-left" v-if="form.hasErrors">
                <div class="hint-panel__body">
                    <div class="hint-panel__header">
                        <div class="hint-panel__title">
                            Could not create new list.
                        </div>
                    </div>
                    <div class="hint-panel__content" v-for="error in Object.values(form.errors)">
                        <p>{{ error }}</p>
                    </div>
                </div>
            </div>
            <!--/End old errors-->
            <!--Wish List Name-->
            <div class="dialog__content--row">
                <label class="form-label" for="wishlist-name">{{ listTypeMoniker }} List Name</label>
                <input class="input" type="text" name="list-name" :placeholder="listNameRandom" v-model="form.name">
            </div>
            <!--List Type-->
            <div class="dialog__content--row" v-if="!isEdit">
                <label for="list-type" class="form-label">List Type</label>
                <div class="select">
                    <select name="list-type" id="list-type" v-model="form.type">
                        <option value="wish" v-if="!profileUser?.is_gift_hero">Wish List</option>
                        <option value="expert">Expert List</option>
                    </select>
                </div>
            </div>
            <!--Wish List Privacy-->
            <div class="dialog__content--row">
                <!--Wish List Privacy-->
                <div class="form-control">
                    <h2 class="form-label">Who Can See this {{ listTypeMoniker }} List?</h2>
                    <div class="form-control__button-option form-control__button-option--stacked">
                        <label class="form-control--radio-button">
                            <input type="radio" name="list-visibility" value="public" v-model="form.privacy">
                            <div class="control__indicator control__indicator--equal control__indicator--privacy-public">
                                <i class="icon"><icon-public/></i><span class="label">Public</span>
                            </div>
                        </label>
                        <span class="control__description">Anyone online</span>
                    </div>
                    <div class="form-control__button-option form-control__button-option--stacked">
                        <label class="form-control--radio-button">
                            <input type="radio" name="list-visibility" value="private" v-model="form.privacy">
                            <div class="control__indicator control__indicator--equal control__indicator--privacy-private-link">
                                <i class="icon"><icon-private-link/></i><span class="label">Private Link</span>
                            </div>
                        </label>
                        <span class="control__description">Only those with a link to {{ listTypeMoniker }} list</span>
                    </div>
                </div>
            </div>
            <privacy-hint></privacy-hint>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="moveOrCopyGiftToNewWishList" :disabled="form.processing">{{ buttonText }}</button>
        </template>
    </modalog>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import { useAPIForm } from "@/composables/useAPIForm";
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import { useModalogStore } from "@/stores/modalog";
import IconPublic from '~/svg/ui-card-privacy-public.svg'
import IconPrivateLink from '~/svg/ui-card-privacy-private-link.svg'
import PrivacyHint from "@/Components/hints/PrivacyHint.vue";
import * as Sentry from "@sentry/vue";

const cardOptionsStore = useCardOptionsMenuStore();
const modalogStore = useModalogStore();

const props = defineProps({
    isOwner: {
        type: Boolean,
        default: false
    },
    item: {
        type: Object,
        default: null
    }
});

const listName = ref('');
const listPrivacy = ref('public');
const listType = ref('wish');
const newList = ref(null);

const form = useAPIForm({
    name: '',
    privacy: 'public',
    type: 'wish',
    errors: {},
});

onMounted(() => {
    form.reset();

    if ( props.item.hasOwnProperty('giftlist') ) {
        props.isOwner = props.item.giftlist.is_owner;
    }

    /* Set list type to `expert` if user is a Gift Hero expert. */
    if (user.value.is_gift_hero) {
        listType.value = 'expert';
    }
});

const apiAction = computed(() => {
    return props.isOwner ? 'moveItemToWishList' : 'copyItemToWishList';
});

const actionVerb = computed(() => {
    return props.isOwner ? 'moved' : 'copied';
});

const buttonText = computed(() => {
    return props.isOwner ? 'Create & Move' : 'Create & Copy';
});

const currentView = computed(() => {
    return usePage().component;
});

const isItemDetail = computed(() => {
    return currentView.value = 'Shared/ItemDetail';
});

const listTypeMoniker = computed(() => {
    return form.type.charAt(0).toUpperCase() + form.type.substring(1);
});

const user = computed(() => {
    return usePage().props.auth.user?.data;
});

const titleText = computed(() => {
    return props.isOwner ? `Move to New ${listTypeMoniker.value} List` : `Copy to New ${listTypeMoniker.value} List`;
});

function moveOrCopyGiftToNewWishList () {
    form.post(route('giftlist.store'), {
        preserveScroll: true,
        onBefore: () => { modalogStore.isThrobbing = true; },
        onFinish: () => { modalogStore.isThrobbing = false; form.reset(); },
        onSuccess: (newGiftlist) => {
            axios({
                method: props.isOwner ? 'put' : 'post',
                url: route(props.isOwner ? 'item.move' : 'item.copy', { item: props.item }),
                data: {
                    item_uuid: props.item.uuid,
                    list_uuid: newGiftlist.uuid,
                }
            })
                .catch(error => {
                    console.error(error);
                    alert(`There was an error ${props.isOwner ? 'moving' : 'copying'} the item.`);
                    Sentry.captureException(error);
                })
                .finally(() => {
                    modalogStore.closeModalog();
                    cardOptionsStore.closeAll();
                    modalogStore.isThrobbing = false;
                    form.reset();
                    props.item.is_processing = true;
                    props.item.is_processing_text = `${props.item.name} ${actionVerb.value} to ${newGiftlist.name}`;

                    setTimeout(() => {
                        router.reload({
                            preserveScroll: true,
                            only: ['items'],
                        });
                    }, 3000);
                })
        },
        onError: (error) => {
            console.error(error);
            alert(`There was an error ${props.isOwner ? 'moving' : 'copying'} the item.`);
            Sentry.captureException(error);
        }
    });
}
</script>
